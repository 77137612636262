.loader-inner {
  display: flex;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 9px solid #dbdcef;
  border-right-color: #2A2F35;
  animation: spinner-rotate 1s infinite linear;
}

@keyframes spinner-rotate {
  to {
    transform: rotate(1turn);
  }
}