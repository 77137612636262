@import './reset.css';
@import './variables.module';

* {
  box-sizing: border-box
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
  url("./fonts/Poppins-Bold.ttf") format("trueType");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
  url("./fonts/Poppins-Regular.ttf") format("trueType");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
  url("./fonts/Poppins-SemiBold.ttf") format("trueType");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
  url("./fonts/Poppins-Light.ttf") format("trueType");
}

@font-face {
  font-family: "AvanGarde-Regular";
  src: local("AvantGarde-Regular"),
  url("./fonts/ITC-Avant-Garde-Gothic-Book-Regular.otf") format("trueType");
}

@font-face {
  font-family: "AvanGarde-Bold";
  src: local("AvantGarde-Bold"),
  url("./fonts/ITC-Avant-Garde-Gothic-LT-Bold.otf") format("trueType");
}
body {
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #2A2F35;
  }

}
.container {
  padding: 0 32px;
}

.flexAlignEnd {
  display: flex;
  align-items: flex-end;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}
.flexAlignStart {
  display: flex;
  align-items: flex-start;
}

.mt15 {
  margin-top: 15px;
}
.mt30 {
  margin-top: 30px;
}

.mt60 {
  margin-top: 30px;
}
.mt64 {
  margin-top: 32px;
}

.mr20 {
  margin-right: 10px;
}

.regularInput-Select {
  max-width: $searchInputWidth;
  width: 100%;
  position: relative;
}

.calendarInput {
  width: 340px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.rangeCalendarInput {
  width: 286px;
  display: flex;
  align-items: center;
}

.largeSelect {
  width: $largeSelectWith;
}

.hugeSelect {
  width: $hugeSelect;
  margin-bottom: 40px;
}

.regularBtn {
  width: $regularButtonWidth;
  margin-right: 10px;
}

.smallBtn {
  width: $smallButtonWidth;
}
.select__menu {
  z-index: 10000 !important;
}






