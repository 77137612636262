.title {
  &Text {
    position: relative;
    font-size: 16px;
    color: #444444;
    font-family: Poppins-Bold, sans-serif;
    text-transform: capitalize;
    z-index: 0;
  }
}
