.contextMenu {
  position: relative;
  background: #F2F2F2;
  width: 235px;
  padding-top: 15px;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 13px;
  color: #2A2F35;
  z-index: 1000;
  border: .5px solid #495464;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background: #495464;
      color: white;
    }

  }
}