@import '../../../src/mixins';

.formInner {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgotPasswordForm {
  position: relative;
  width: 500px;
  height: 400px;
  box-shadow: 0 4px 13px #00000029;
  border-radius: 11px;
  background:white;
  padding-top: 50px;
  z-index: 100;
  .formTitle {
    margin-bottom: 40px;
    font-size: 16px;
    text-align: center;
    font-family: "Poppins-SemiBold", sans-serif;
    text-transform: uppercase;
  }
  .submitBtn {
    width: 140px;
    margin: 0 auto;
    padding-top: 40px;
  }
  .inputInner {
    margin-right: -20px;
    .formInput {
      width: 400px;
      margin: 0 auto;
    }
  }

  .formFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #2A2F35;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
  }
}

.formError {
  @include formErrorBlock;
  margin-top: 2px;
}