@import '../../../mixins.scss';

.wrapper {
  @include tableWrapper;
  padding-bottom: 32px;
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #2A2F35;
  }

}

.auditTable {
  @include table
}

.tableHeadWrapper {
  @include tableHeadWrapper;
}

.tableBodyWrapper {
  @include tableBodyWrapper;

}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}
.tableDescription {
  white-space: initial;
}

.tableHeader {
  padding: 5px 30px !important;
}
.modifiedField {
  background-color: #FF3466;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
