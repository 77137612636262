@import '../../../mixins.scss';

.wrapper {
  @include tableWrapper;
  position: relative;
  max-height: 500px;
  padding-bottom: 32px;
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #2A2F35;
  }

}

.auditTable {
  @include table
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  height: 50px;
  position: sticky;
  top: 0;

}

.tableBodyWrapper {
  @include tableBodyWrapper;

}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}
.tableDescription {
  height: 90px;
  white-space: initial;
}

.tableHeader {
  padding: 5px 30px !important;
}

.modifiedField {
  border-bottom: 2px solid #FF3466;
  white-space: nowrap;
}
