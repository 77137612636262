@import 'src/mixins';
@import '../../../../../src/pages/Management/Events/style.module';

.eventsModal {
  @include modalWindow;
  z-index: 10000;
  .modalForm {
    position: relative;
    padding: 30px;
    background-color: white;
    display: flex;
    width: 1000px;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalTitle {
    padding-left: 20px;
  }
  .closeBtn {
    position: absolute;
    height: 32px;
    width: 32px;
    border: none;
    cursor: pointer;
    outline: none;
    background: transparent;
    padding: 0;
    top: 15px;
    right: 15px;
    z-index: 1000;
    transition: .3s;

    &:before {
      content: '';
      display: block;
      background: url('../../../../img/RemoveIcon.svg') no-repeat;
      width: 30px;
      height: 30px;
      transition: .3s;
    }

    &:hover.closeBtn:before {
      content: '';
      display: block;
      background: url('../../../../img/RemoveIconActive.svg') no-repeat;
      width: 30px;
      height: 30px;
      transition: .3s;
    }
  }
}
.timeCalendarWrapper {
  font-family: "Poppins", sans-serif !important;
  position: absolute ;
  z-index: 1000 !important;
  font-size: 10px;
  top: -200px !important;
}
.formError {
  @include formErrorBlock;
  margin-top: -20px;
}