@import '../../../../src/mixins';
@import '../../../../src/variables.module';

.loginTitle {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 29px;
    letter-spacing: 0;
    color: #2B2E34;
    margin-bottom: 30px;
}
.formLogo {
    width: 44px;
    height: 56px;
}
.inputsInner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 504px;
}
.loginInput {
    @include loginInput;
    margin: 5px 0 15px 0;
}
.loginHelper {
    display: flex;
    justify-content: space-between;
    max-width: 398px;
}
.checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-family: "Poppins-Regular", sans-serif;
    color: #151515;

}

.checkbox label:before {
    content:'';
    width: 15px;
    height: 15px;
    -webkit-appearance: none;
    background-color: #151515;
    border: 1px solid #CDD0D5;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 3px;
    margin-right: 10px;

}
.checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 4px;
    height: 7px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.rememberPasswordLink {
    font-size: 14px;
    font-family: "Poppins-Regular", sans-serif;
    text-decoration: underline;
    color: $activeMenuItem;
    cursor: pointer;
}
.signInInner {
    display: flex;
    .signInBtn {
        width: 310px;
        height: 77px;
        padding: 20px;
        background: $activeMenuItem;
        color: white;
        outline: none;
        border-style: none;
        font-size: 22px;
        font-family: "Poppins-Regular", sans-serif;
        margin-top: 45px;
        cursor:pointer;
        transition: .3s;
        text-align: left;
        &:hover {
            background: #495464;
        }
    }
}

.appVersion {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-family: "Poppins-Regular", sans-serif;
    color: $activeMenuItem;
    font-size: 14px;
}

.errorInner {
    padding: 5px;
}

.formErrorBlock {
    @include formErrorBlock
}

.firstFormWrapper {
    display: flex;
    &Right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &Left {
        position: relative;
        padding: 334px 0 20px 126px;
        width: 50%;
        height: 100vh;
        background: url("../../../img/firstStepLoginBg.png") center center/cover;
        color: white;
        font-family: AvanGarde-Regular, 'sans-serif';
        font-size: 38px;
        &Descr {
            font-size: 86px;
            margin-top: 15px;
            span {
                font-family: AvanGarde-Bold, 'sans-serif';
            }
        }
        img {
            display: block;
            max-width: 100%;
        }
        svg {
            display: block;
            max-width: 100%;
        }
    }
    &Powered {
        display: flex;
        position: absolute;
        font-size: 19px;
        bottom:  75px;
        img {
            margin-left: 10px;
        }
    }
}

.inputLabel {
    color: #6E6E6E;
    font-size: 14px;
    font-family: "Poppins-Regular", sans-serif;
    &Wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }
}
