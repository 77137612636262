@import "/src/variables.module";

.dropdownMenu {
  width: 100%;
  min-height: 52px;
  padding: 10px;
  font-family: Poppins-SemiBold, sans-serif;
  text-decoration: none;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #2A2F35;
  background-color: white;

  a {
    text-decoration: inherit;
    font-size: inherit;
    padding-bottom: 10px;
    color: inherit;
    transition: .3s;

    &:hover {
      color: $activeMenuItem;
    }
  }

  a:last-child {
    padding: 0;
  }

  .active {
    color: $activeMenuItem;
  }

  .link {
    text-decoration: inherit;
    font-size: inherit;
    padding-bottom: 10px;
    color: inherit;
    transition: .3s;
  }

  span:hover {
    color: $activeMenuItem;
  }
}