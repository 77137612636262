.fixtureContextMenu {
  background: #F2F2F2;
  border: .5px solid #495464;
  width: 235px;
  position: relative;
  z-index: 1000;
  .linksList {
    width: 100%;
    font-family: "Poppins-Regular", sans-serif;
    .link {
      padding-left: 25px;
      padding-top: 14px;
      padding-bottom: 14px;
      cursor: pointer;
      transition: .2s;
      &:hover {
        background: #495464;
        color: white;
      }
    }
  }
}