.columnInputFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.tableTitle {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
