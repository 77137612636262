.filter {
  position: absolute;
  top: 50px;
  right: -40px;
  z-index: 2000 !important;
  border: 0.5px solid #495464;
  background-color: #F1F1F1;
  color: #495464;
  width: 250px;
  padding: 5px 0 10px 10px;
}

.closeBtnInner {
  position: relative;
}

.closeBtn {
  position: absolute;
  z-index: 2000 !important;
  top: -1px;
  right: 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  & > .closeImg {
    width: 8px;
    height: 8px;
  }
}
