.rrt-text {
    word-break: keep-all;
}

  .toastr {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    padding-bottom: 10px;

    &.rrt-success {
      background-color: #0ABAB5 !important;

      .rrt-progressbar {
        background-color: darken(#0ABAB5, 15%) !important;
      }
    }

    &.rrt-error {
      background-color: #EA4969 !important;

      .rrt-progressbar {
        background-color: darken(#EA4969, 15%) !important;
      }
    }
  }