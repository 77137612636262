@import '../../../../mixins';
@import '../../../../variables.module.scss';

.wrapper {
  @include tableWrapper;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tableSort {
  margin: -1px 3px;
  width: 10px;
  height: 10px;
}

.tableSortAsc {
  margin: -1px 3px;
  transform: rotateZ(180deg);
  width: 10px;
  height: 10px;
}

.tableSortingHeader {
  padding: 20px 40px !important;
  cursor: pointer !important;

  &:hover {
    color: $activeMenuItem;

    path {
      fill: $activeMenuItem;
    }
  }
}

.tableInfoBlock {
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  .text {
    font-size: 24px;
    font-family: "Poppins-Regular", sans-serif;
    text-align: center;
    color: #444444;
  }
}

.tableControls {
  display: flex;
  align-items: flex-end;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
  max-width: 986px;
  width: 100%;
  margin-bottom: 20px;
}

.decodersTable {
  @include table;
  min-width: 400px;
  max-width: 986px;
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  border-bottom: 20px solid white;
  height: 58px;
}

.tableBodyWrapper {
  @include tableBodyWrapper;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}

.tableDescDecoder {
  @include tableHeaderAndDescription;
  width: 20%;
}

.tableHeaderDeleted {
  @include tableHeaderAndDescription;
  text-align: left;
}

.tableDescDeleted {
  @include tableHeaderAndDescription;
  text-align: left;
  margin-left: 100px;
}

.decodersCheckbox {
  margin-left: 18px;
}

.controlBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  .updateBtn {
    @include tableBtnUpdate
  }

  .removeBtn {
    @include tableBtnRemove
  }
}

.undeleteBtn {
  display: block;
  height: 30px;
  font-family: "Poppins-Regular", sans-serif;
  background-color: #2A2F35;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
  text-align: center;
  outline: none;
  text-transform: capitalize;

  &:hover {
    background-color: #dcdee1;
    color: #1c2229;
  }
}
