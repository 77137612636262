.toggleSwitcher {
  display: flex;
  flex-direction: column;
  font-family: Poppins-Regular, sans-serif;
  font-size: 14px;
  color: #2A2F35;
  height: 17px;

}

.toggleSwitcherLabel {
  margin-top: 9px;
  display: block;
}

