.realCheckbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
  transition: 0.3s all;
  &:checked + .customCheckbox:before {
    content: '';
    display: inline-block;
    background-image: url(../../img/checkedIcon.svg);
    transition: 0.2s ease-in;
  }
  &:focus + .customCheckbox {
    box-shadow: 0 0 0 3px #fcb8c9;
  }
}

.customCheckbox {
  position: relative;
  display: inline-block;
  height: 17px;
  width: 17px;
  background: #fff;
  border: 2px #161616 solid;
  border-radius: 2px;
  margin-right: 4px;
  cursor: pointer;
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: -1px;
    top: -1px;
  }
}
