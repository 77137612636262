.warningModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(73, 84, 100, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins-Regular", sans-serif;
  z-index: 1000;

  .content {
    min-width: 410px;
    min-height: 210px;
    background-color: white;

    .titleBlock {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2A2F35;

      .attention {
        width: 50px;
        height: 50px;
        border: 2px solid #FF3466;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: #FF3466;
      }
    }

    .contentInner {
      padding: 20px 25px;

      .contentText {
        color: #2A2F35;
        font-size: 18px;
        text-align: center;


      }

      .btnInner {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;

        .btn {
          width: 100px;
        }
      }
    }
  }
}