.spinner-inner {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 1000;
}
.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #dbdcef;
  border-right-color: #2A2F35;
  animation: spinner-rotate 1s infinite linear;
}

@keyframes spinner-rotate {
  to {
    transform: rotate(1turn);
  }
}