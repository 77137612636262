@import '../../../../mixins';

.managementModalInner {
  @include modalWindow;

  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .modalForm {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 20px 40px 40px;
    background-color: white;
    height: 600px;
  }

  .switch {
    margin-top: -23px;
  }

  .createBtn {
    margin-right: 20px;
  }

  .formError {
    @include formErrorBlock;
  }

  .closeBtn {
    @include closeBtn;
  }
}