@import '../../../mixins.scss';
@import '../../../variables.module.scss';

.totalWrapper {
  position: relative;
  padding-bottom: 32px;
}
.wrapper {
  @include tableWrapper;
  margin-top: 10px;
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #2A2F35;
  }

}

.auditTable {
  @include table;
  .cellWithCheckbox {
    display: flex;
    align-items: center;
    .checkbox {
      margin-right: 15px;
      cursor: pointer;
    }
  }
}

.tableHead {
  @include tableHeadWrapper;
  border: none;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
}

.tableBody {
  @include tableBodyWrapper;;
}
.tableBodyHidden {
  display: none;
}
.tableBodyHidden {
  @include tableBodyWrapper;
  display: block;
  overflow: hidden;
  height: 0;
}

.tableBodyTest {
  @include tableBodyWrapper;
}
.tableBodyHidden {
  display: none;
}

.tableDateItemsRow {
  display: block;
  position: relative;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 14px;
  padding-bottom: 8px;
  margin-top: 20px;
  color: #444444;
  white-space: nowrap;
  padding-left: 15px;
}

.tableDate {
  cursor: pointer;
  margin-right: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -10px;
    top: 5px;
    transform: rotate(125deg);
  }

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -14px;
    top: 5px;
    transform: rotate(-310deg);
  }
}
.tableDateActive {
  cursor: pointer;
  margin-right: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -10px;
    top: 5px;
    transform: rotate(45deg);
  }

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -14px;
    top: 5px;
    transform: rotate(-45deg);
  }
}

.tableItems {
  position: absolute;
  left: 160px;
  top: -1px;
}

.tableSort {
  margin: -1px 3px;

}
.tableSortAsc {
  margin: 2px 3px;
  transform: rotatez(180deg);
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}

.tableSortingHeader {
  cursor: pointer;
  &:hover {
    color: $activeMenuItem;

    path {
      transition: all 0.3s;
      fill: $activeMenuItem;
    }
  }
}

.tableRow {

}
.fixtureMenu {
  position: absolute;
  top: 0;
  left: 0;
}

.blue {
  background-color: #495464;
  color: white;
}
.tableControl {
  display: flex;
}
.createBtn {
  display: inline-block;
}