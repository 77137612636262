
.footer {
  display: flex;
  justify-content: flex-end;

  &Input {
    width: 87px;
    transform: translate(9px, -3px);
  }

  &Pagination {
    display: flex;
    align-items: center;

    &Btn {
      width: 48px;
      margin-right: 10px;
    }
  }

  &RightBtnGroup {
    display: flex;
  }

  &RightBtn {
    width: 178px;
    margin-left: 10px;
  }
}