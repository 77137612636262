@import '../../../../../mixins';

.userManagementInner {
  @include modalWindow;


  .modalForm {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 20px 40px 40px;
    background-color: white;
    width: 454px;
  }

  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .closeBtn {
    @include closeBtn
  }

  .inputInner {
    margin-top: 20px;
  }

  .formError {
    @include formErrorBlock;
    margin: 5px 0 0 0;
  }

  .submitButtonContainer{
    display: flex;
    justify-content: flex-end;
    margin: 20px 10px 0 0;

    & div {
      width: 140px;
    }
  }
}