.calendar {
  margin-bottom: 18px;
  margin-right: 10px;
}

.scheduleTableWrapper {
  padding-bottom: 32px;
}

.smallBtn {
  width: 38px;
  height: 38px;
  background-color: #2A2F35;
  border: none;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #dcdee1;
  }

  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
  }
}

.refreshBtn {
  &:before {
    background-image: url('../../../src/img/RefreshIconWhite.svg');
  }

  &:hover.refreshBtn:before,
  &:active.refreshBtn:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../../../src/img/RefreshIconDark.svg');
    background-color: #dcdee1;
  }
}

.svgBtn {
  margin-right: 10px;

  &:before {
    background-image: url('../../../src/img/DownloadWhite.svg');
  }

  &:hover.svgBtn:before,
  &:active.svgBtn:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../../../src/img/DownloadDark.svg');
    background-color: #dcdee1;
  }
}
