@import './variables.module';

@mixin formErrorBlock {
  color: #FF3466;
  font-size: 12px;
  font-family: "Poppins-Regular", sans-serif;
  margin-top: -10px;
  margin-bottom: 5px;
  text-align: center;
}

@mixin tableWrapper {
  overflow: auto;
}

@mixin table {
  font-size: 13px;
  width: 100%;
}

@mixin tableHeadWrapper {
  font-family: Poppins-SemiBold, sans-serif;
  background-color: #2A2F35;
  height: 69px;
  color: white;
  border-bottom: 20px solid white;
}

@mixin tableBodyWrapper {
  font-family: Poppins-Regular, sans-serif;
  background-color: #F1F1F1;
  color: #2A2F35;
  .tableRow {
    border-bottom: 10px solid white;
  }
}

@mixin tableHeaderAndDescription {
  vertical-align: middle !important;
  padding: 5px !important;
  text-align: center;
  white-space: nowrap;
  cursor: default;
  transition: .3s;
}

@mixin searchInput {
  font-family: "Poppins-Regular", sans-serif !important;
  min-height: 38px !important;
  display: -webkit-flex;
  margin: 6px 10px 0 0;
  box-shadow: none !important;
  font-size: 12px !important;
  border: 0 !important;
  border-bottom: 1px #495464 solid !important;
  background-color: #F1F1F1 !important;
  color: #2A2F35 !important;
  cursor: pointer !important;
}

@mixin tableBtnUpdate {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 0;
  transition: .3s;
  margin-right: 15px;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/EditIcon.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
  &:hover.updateBtn:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/EditIconActive.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
}

@mixin tableBtnRemove {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 0;
  transition: .3s;
  margin-right: 15px;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/RemoveIcon.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
  &:hover.removeBtn:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/RemoveIconActive.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
}

@mixin tableBtnReset {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 0;
  transition: .3s;
  margin-right: 15px;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/ResetIcon.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
  &:hover.resetBtn:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../src/img/ResetIconActive.svg');
    background-size: contain;
    background-position: center;
  }
}

@mixin modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(73, 84, 100, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

@mixin closeBtn {
  position: absolute;
  height: 32px;
  width: 32px;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 0;
  top: 15px;
  right: 15px;
  transition: .3s;
  &:before {
    content: '';
    display: block;
    background: url('./img/RemoveIcon.svg') no-repeat;
    width: 30px;
    height: 30px;
    transition: .3s;
  }
  &:hover.closeBtn:before {
    content: '';
    display: block;
    background: url('./img/RemoveIconActive.svg') no-repeat;
    width: 30px;
    height: 30px;
    transition: .3s;
  }
}

@mixin loginInput {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 8px 0 10px;
  color: black;
  background-color: white;
  border: none;
  border-bottom: 1px solid #8D8D8D;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins-Regular", sans-serif;
  outline: none;
  transition: .1s all;
  &:focus {
    border: 3px solid $activeMenuItem;
    border-radius: 0;
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    font-family: "Poppins-Regular", sans-serif;
    color: #8D8D8D;
  }
}

@mixin loginBtn {
  height: 50px;
  background: #2A2F35;
  color: white;
  font-size: 25px;
  outline: none;
  border-style: none;
  font-family: "Poppins-Regular", sans-serif;
  text-transform: uppercase;
  cursor: pointer;
}
