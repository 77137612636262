@import '../../../mixins.scss';

.modalInner {
  @include modalWindow;

  .sendEmailForm {
    background: white;
    width: 600px;
    min-height: 400px;
    position: relative;
    padding: 50px 30px 50px 50px;
    font-family: "Poppins-Regular", sans-serif;

    .closeBtn {
      @include closeBtn;
    }

    .inputInner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

  }
  .addressBtnInner {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    .addressBtn {
      width: 200px;
      justify-content: flex-end;
      margin-top: -30px;
    }
  }
  .addressInput {
    max-width: 520px;
    margin: 6px 10px 10px 10px;
  }
  .textAreaInner {
    width: 100%;
    padding-left: 10px;
    margin-top: 10px;

    .textArea {
      resize: none;
      width: 100%;
      min-height: 200px;
      outline: none;
      font-family: inherit;
      padding: 15px;
    }
  }

  .subjTextAreaInner {
    width: 100%;
    padding-left: 10px;
    min-height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;

    .subjectTextArea {
      resize: none;
      width: 100%;
      min-height: 100px;
      outline: none;
      font-family: inherit;
      padding: 15px;
    }
  }

  .sendBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 27px;

    .sendBtn {
      width: 120px;
      padding-top: 20px;
      margin-left: auto;
    }
  }
}