@import '../../mixins';

.table-select {
  &__control {
    font-family: "Poppins-Regular", sans-serif !important;
    min-height: 15px !important;
    display: -webkit-flex;
    margin: 0 20px 0 0;
    box-shadow: none !important;
    font-size: 13px !important;
    border: 0 !important;
    border-bottom: 1px #495464 solid !important;
    background-color: #F1F1F1 !important;
    color: #2A2F35 !important;
    cursor: pointer !important;
    width: 100%;
  }
  &__placeholder {
    text-align: initial;
  }

  &__menu {
    font-family: "Poppins-Regular", sans-serif !important;
    box-shadow: 0 14px 24px #0000003D !important;
    font-size: 13px !important;
    cursor: pointer !important;
    z-index: 10000 !important;
    position: fixed;
    color: #1c2229;
  }

  &__single-value {
    overflow: initial !important;
    text-align: left;
  }

  &__indicator-separator {
    display: none;
  }

  &__value-container {
    flex-direction: row !important;
  }
  &__indicator {
    padding: 4px !important;
  }
}

.inputLabel {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 14px;
  color: #444444;
}
