.title {
  margin-top: 32px;
}
.filter {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  .filterLeftSide {
    display: flex;
    width: 100%;
  }
  .filterRightSide {
    display: flex;
    align-items: flex-end;
    .switch {
      align-self: flex-start;
      margin-top: 20px;
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.clearFilterBtn {
  width: 100px;
  margin-top: 23px;
  margin-right: 20px;
}

.addEventWrapper {
  display: flex;
  margin-top: 20px;
}

.inputsColumn {
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}

.inputWrapper {
  width: 100%;
  margin-bottom: 30px;
}

.inputDescription {
  position: relative;
}

.inputDescriptionNumber {
  font-family: Poppins-Bold, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #FAFAFA;
  font-size: 16px;
  width: 47px;
  height: 38px;
  background: #2A2F35;
  position: absolute;
  right: 10px;
  top: 18px;
}

.inputSmallWrapper {
  width: 50%;
}

.homeAwayWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btnWrapper {
  width: 90px;
  margin-left: 28px;
}

.btnGroupWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radioControls {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  margin-right: 17px;
  color: #2A2F35;
  font-size: 12px;
  font-family: Poppins-Bold, sans-serif;
}

.radioScheduleBtn {
  width: 10%;
  display: flex;
  margin-bottom: 10px;
}

.radioVerifyBtn {
  width: 10%;
  display: flex;
}

.radioScheduleLabel {
  padding: 3px 0 0 6px;
}

.radioVerifyLabel {
  padding: 8px 0 0 6px;
}

.btnAdd {
  position: relative;

  &:hover {
    .btnAddArrow {
      fill: #2A2F35;
    }
  }
}

.btnAddArrow {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  fill: white;
}

