.calendarBtn {
  position: absolute;
  right: 10px;
  height: 38px;
  width: 52px;
  background-color: #2A2F35;
  border: none;
  cursor: pointer;
  padding-left: 8px;

  &:hover {
    background-color: #23272a;
  }

  &:before {
    content: '';
    display: block;
    background: url('../../img/calendar.svg') no-repeat;
    width: 30px;
    height: 30px;
    margin: 2px 0 0 6px;
  }
}

.calendarWrapper {
  width: 100%;
  position: relative;
}

.custom-header {
  background: #FF3466 !important; ;
  font-family: "Poppins", sans-serif !important;
}

.custom-days {
  .is-week-days {
    color: #d20000;
    font-family: "Poppins", sans-serif !important;
  }
  .is-disabled {
    color: #444;
    font-family: "Poppins", sans-serif !important;
  }
  .is-selected-day {
    background: #FF3466 !important;
    border: none !important;
    font-family: "Poppins", sans-serif !important;
  }
}
.datepicker-input {
  background: red;
  font-family: "Poppins", sans-serif !important;
  max-width: 410px;
}
.schedule-calendar {
  bottom: -150px !important;
  font-family: "Poppins", sans-serif !important;
}


