@import '../../mixins';

.searchInput {
  @include searchInput;
  font-size: 13px !important;
  position: relative;
  padding: 13px;
  max-height: 38px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 12px;
  }

  &:disabled {
    color: #707070 !important;
    border: none !important;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputLabel {
  display: block;
}