@import '../../../../mixins';

.wrapper {
  @include tableWrapper;
  overflow: visible;
  position: relative;
  margin-top: 20px;
}

.eventsTable {
  @include table;
  min-width: 400px;
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  border-bottom: 20px solid white;
  height: 58px;
}

.tableBodyWrapper {
  @include tableBodyWrapper;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}

.controlBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  .updateBtn {
    @include tableBtnUpdate
  }
  .removeBtn {
    @include tableBtnRemove
  }
}




