@import '../../../mixins.scss';


.container {
  padding: 32px;
}

.mainTitle {
  margin-bottom: 15px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.searchContainer {
  width: 394px;

  & input {
    background-image: url('../../../img/search.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    padding-left: 30px;
  }

  & input:focus {
    background-image: none;
    padding-left: 13px;
  }
}

.addUserButton {
  width: 140px;
}

.tableContainer{
  position: relative;
}

.tableWrapper {
  width: 100%;
  table-layout: fixed;
  border-collapse: initial;
  border-spacing: 0 10px;
}

.theadRow {
  background-color: #2A2F35;
  font-family: 'Poppins-SemiBold', serif;

  & th {
    color: #FAFAFA;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    padding: 15px 0;
    cursor: default;
  }

  & th:first-child {
    width: 25%;
  }

  & th:nth-child(2) {
    width: 20%;
  }

  & th:nth-child(3) {
    width: 15%;
  }

  & th:nth-child(4) {
    width: 22%;
  }

  & th:last-child {
    width: 8%;
  }
}

.tableTitle {
  display: flex;
  justify-content: center;

  & p {
    padding-right: 15px;
  }
}

.ascIcon {
  transform: rotateZ(180deg);
  transition: .3s;
}

.descIcon {
  transition: .3s;
}

.tbodyRow {
  & td {
    text-align: center;
    padding: 10px 4px;
    background-color: #F1F1F1;
    font-family: 'Poppins-Regular', serif;
    font-size: 13px;
    line-height: 18px;
    word-break: break-word;
    color: #2A2F35;
    vertical-align: middle;
  }

  & td:first-child {
    padding: 10px 4px 10px 20px;
  }

  & td:last-child {
    padding: 5px 20px 5px 4px;
    text-align: -webkit-right;
  }
}

.actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 180px;

  & img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}

.updateBtn {
  @include tableBtnUpdate;
}

.updateBtn::after {
  content: '';
  position: absolute;
  left: 30px;
  top: -30px;
  z-index: 10;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.updateBtn:hover::after {
  transition: .5s;
  content: 'Edit User';
  background-color: #2A2F35;
}

.removeBtn {
  @include tableBtnRemove;
}

.removeBtn::after {
  content: '';
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: 10;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.removeBtn:hover::after {
  transition: .5s;
  content: 'Delete User';
  background-color: #2A2F35;
}

.resetBtn {
  @include tableBtnReset;
}

.resetBtn::after {
  content: '';
  position: absolute;
  right: -20px;
  top: -30px;
  z-index: 10;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

.resetBtn:hover::after {
  transition: .5s;
  content: 'Reset password';
  background-color: #2A2F35;
}