@import '../../mixins';

.select {
  &__control {
  @include searchInput;
  }

  &__menu {
    font-family: "Poppins-Regular", sans-serif !important;
    width: calc(100% - 20px) !important;
    box-shadow: 0 14px 24px #0000003D !important;
    font-size: 16px !important;
    cursor: pointer !important;
    z-index: 1 !important;
    color: #1c2229;
  }

  &__single-value {
    overflow: initial !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__value-container {
    flex-direction: row !important;
  }
  &__indicator {
    padding: 4px !important;
  }
}

.inputLabel {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 12px;
  color: #444444;
}
