@import '../../../mixins.scss';
@import '../../../variables.module.scss';

.wrapper {
  @include tableWrapper;
  width: 100%;
  padding-bottom: 32px;
}

.auditTable {
  @include table;
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  border-bottom: 20px solid white;
  height: 58px;
}
.tableControls {
  display: flex;
  align-items: flex-end;
}
.tableInfoBlock {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  .text {
    font-size: 24px;
    font-family: "Poppins-Regular", sans-serif;
    text-align: center;
    color: #444444;
  }
}
.tableBodyWrapper {
  @include tableBodyWrapper;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}
.tableDescriptionTextEnd {
  @include tableHeaderAndDescription;
  text-align: end !important;
}
.controlBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  .updateBtn {
   @include tableBtnUpdate
  }
  .removeBtn {
   @include tableBtnRemove
  }
}

.tableSort {
  margin: -1px 3px;
  width: 10px;
  height: 10px;
  background-color: #161616;
}

.tableSortAsc {
  margin: -1px 3px;
  transform: rotateZ(180deg);
  width: 10px;
  height: 10px;
  background-color: #161616;
}

.tableSortingHeader {
  padding: 20px 40px !important;
  cursor: pointer !important;

  &:hover {
    color: $activeMenuItem;

    path {
      fill: $activeMenuItem;
    }
  }
}

