$searchInputWidth: 240px;
$calendarInputWidth: 286px;
$largeSelectWith: 394px;
$hugeSelect: 500px;
$regularButtonWidth: 140px;
$smallButtonWidth: 118px;
$headerBgColor: #20232A;
$activeMenuItem: #EA4969;
$greenTable: #90EB90;
$redTable: #EB9090;
$yellowTable: #EBEB90;
$blueTable : #495464
