@import '../../variables.module';

.header {
  position: sticky;
  width: 100%;
  font-family: "Poppins-Bold", sans-serif;
  height: 110px;
  padding: 0 64px;
  background-color: $headerBgColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2000;
}

.logo {
  display: block;
  max-width: 100%;
}

.active, .link {
  position: relative;
  color: white;
  font-size: 19px;
  text-decoration: none;
  margin: 0 50px;
  cursor: pointer;

  .linkMenu {
    display: block;
    width: 155px;
    height: fit-content;
    position: absolute;
    top: 30px;
    left: 0;
    transition: 0.5s;
    -webkit-box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.37);
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.37);
    z-index: 1000;
  }

  &:after {
    content: '';
    display: block;
    margin-top: 10px;
    width: 0;
    height: 5px;
    background-color: $activeMenuItem;
  }

  &:hover {
    color: $activeMenuItem;

    path {
      fill: $activeMenuItem;
    }
  }

  .userLinkMenu {
    top: 40px;
  }
}

.active {
  color: $activeMenuItem;

  &:after {
    width: 100%;
  }

  path {
    fill: $activeMenuItem;
  }
}

.navbar {
  list-style-type: none;
  display: flex;
}

.itemMore {
  margin-left: 10px;
  transform: rotateZ(-90deg);
  transition: .3s;

  &:hover + .linkMenu {
    display: block;
    transition: .5s;
  }
}

.itemMoreActive {
  margin-left: 10px;
  transition: .3s;
}

.loginBtn {
  height: 32px;
  width: 32px;
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 0;
  transition: .3s;
  margin-right: 5px;

  &:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url('../../../src/img/headerLogin.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }

  &:hover.loginBtn:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url('../../../src/img/headerLoginActive.svg');
    background-size: contain;
    background-position: center;
    transition: .3s;
  }
}

.loginBtnActive {
  height: 32px;
  width: 32px;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  background: transparent url('../../../src/img/headerLoginActive.svg');
  margin-right: 5px;
}
