.calendarInput {
  margin-top: -14px;
}
.calendar-label {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 12px;
  color: #444444;
  margin-bottom: 5px;
}
.calendarDecor {
  position: absolute;
  right: 0;
  bottom: -18px;
  height: 38px;
  width: 52px;
  background-color: #2A2F35;
  border: none;
  cursor: pointer;
  padding-left: 8px;

  &:hover {
    background-color: #23272a;
  }

  &:before {
    content: '';
    display: block;
    background: url('../../img/calendar.svg') no-repeat;
    width: 30px;
    height: 30px;
    margin: 2px 0 0 6px;
  }
}

.wrapper {
  width: 290px;
  height: 38px;
  position: relative;
}
.react-calendar-datetime-picker .input-picker .input-picker--input {
  height: 38px !important;
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  background: rgba(73, 84, 100, 0.05);
  font-family: "Poppins-Regular", sans-serif !important;
  text-transform: capitalize;
  text-align: left !important;
  padding-left: 20px !important;
  font-size: 12px;
}
.react-calendar-datetime-picker .is-selectedYearRef {
  color: #FF3466 !important;
  font-size: 16px !important;
}

.react-calendar-datetime-picker .monthList_month.is-selected-month {
  color: #FF3466 !important;
  font-size: 14px !important;
}

.react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0 0 0 2px #FF3466 !important;
}

.range-calendar-header {
  background: #EA4969 !important;;
  font-family: "Poppins", sans-serif !important;
}
.range-calendar-years  {
  font-family: "Poppins", sans-serif !important;
}
.range-calendar-months {
  font-family: "Poppins", sans-serif !important;
}

.range-calendar-days {
  font-family: "Poppins", sans-serif !important;

  .is-selected-day-from {
    background-color: #FF3466 !important;
  }

  .is-selected-day-to {
    background-color: #FF3466 !important;
  }

  .is-selected-day-range {
    background-color: rgba(255, 104, 136, 0.25) !important;
    color: #666 !important;
  }

  .daysList-day {
    font-size: 12px !important;
  }

  .is-week-days {
    font-size: 12px !important;
  }

  .is-selectedYearRef {
    color: #FF3466 !important;
  }

}
.rangePicker {
  top: 60px;
  width: 321px;
  z-index: 1000 !important;
}

.react-calendar-datetime-picker .header .header--btn {
  user-select: none;
}
