@import '../../../../mixins.scss';

.wrapperEventsFixture {
  @include tableWrapper;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 32px;
}
.tableWrapper {
  width: 100%;
  position: relative;
  max-height: 500px;
  overflow: auto;
}
.auditTable {
  @include table;
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  border-bottom: 20px solid white;
  height: 58px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
;
}

.tableBodyWrapper {
  @include tableBodyWrapper;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}
.tableDescriptionTextEnd {
  @include tableHeaderAndDescription;
  text-align: end !important;
}
.controlBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  .addBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    &:hover {
      color: #FF3466;
    }
    .addBtnIcon {
      font-size: 28px;
      font-family: "Poppins-Regular", sans-serif;
      color: inherit;
      transition: .3s;
    }
  }
  .removeBtn {
   @include tableBtnRemove
  }
}
.selectAll {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .selectAllText {
    display: block;
    margin-right: 10px;
  }
}
.titleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.createBtn {
  width: 120px;
  margin-bottom: -10px;
}

