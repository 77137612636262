@import '../../../../../src/mixins';

.channelForm {
  @include modalWindow;
  .modalForm {
    position: relative;
    padding: 30px;
    background-color: white;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .closeBtn {
      position: absolute;
      height: 32px;
      width: 32px;
      border: none;
      cursor: pointer;
      outline: none;
      background: transparent;
      padding: 0;
      top: 15px;
      right: 15px;
      z-index: 1000;
      transition: .3s;
      &:before {
        content: '';
        display: block;
        background: url('../../../../img/RemoveIcon.svg') no-repeat;
        width: 30px;
        height: 30px;
        transition: .3s;
      }
      &:hover.closeBtn:before {
        content: '';
        display: block;
        background: url('../../../../img/RemoveIconActive.svg') no-repeat;
        width: 30px;
        height: 30px;
        transition: .3s;
      }
    }
    .addBtn {
      max-width: 150px;
      margin-top: 20px;
    }
  }
  .formError {
    @include formErrorBlock
  }
}