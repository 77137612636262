@import '../../../../mixins';

.wrapper {
  @include tableWrapper;
  overflow: auto;
  width: 100%;
  position: relative;
}

.tableWrapper {
  max-height: 450px;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #2A2F35;
  }

}

.auditTable {
  @include table;
}

.tableHeadWrapper {
  @include tableHeadWrapper;
  border-bottom: 20px solid white;
  height: 58px;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
}

.tableBodyWrapper {
  @include tableBodyWrapper;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;

  .textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80px;
    width: 100%;
    white-space: nowrap;
    transition: .3s;

    &:hover {
      position: sticky;
      left: 0;
      top: 0;
      overflow: visible;
      white-space: normal;
      transition: .3s;
    }

    &:hover span {
      padding: 5px;
      background-color: #FF3466;
      color: white;
    }
  }
}
.fixtureMenu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.controlBtns {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  .updateBtn {
    @include tableBtnUpdate
  }

  .removeBtn {
    @include tableBtnRemove
  }
}

.blue {
  background-color: #495464;
  color: white;
}

