@import '../../../../src/mixins';
@import '../../../../src/variables.module';

.formOverlay {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(4px);
  z-index: 100;

  .secondStepForm {
    position: relative;
    width: 900px;
    height: 480px;
    background: white;
    display: flex;
    align-items: center;
    font-family: "Poppins-Regular", sans-serif;

    .inputInner {
      margin-top: 30px;
      position: relative;
      display: flex;
      align-items: center;
    }
    .formErrorBlock {
      @include formErrorBlock;
      margin: 5px 0 5px -30px;
    }
  }
}
.leftSide {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    max-width: 80%;
  }
}

.rightSide {
  height: 100%;
  width: 50%;
  padding: 20px;
  position: relative;
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &Cross {
      display: block;
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .formTitle {
    letter-spacing: 0;
    color: #2A2F35;
    font-size: 20px;
    margin: 12px 0;
  }
  .formSubtitle {
    margin: 20px 0;
    font-family: Poppins-Light, sans-serif, sans-serif;
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 24px;
  }
  .verifyInput {
    @include loginInput;
    width: 290px;
    background: #F4F4F4;
    margin-top: 5px;
    &::placeholder {
      font-size: 14px;
      color: #CDD0D5;
      font-family: "Poppins-Regular", sans-serif;
    }
  }
  .accessCodeLabel {
    color: #525252;
    font-size: 12px;
    margin-top: 20px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  &Verify {
    width: 100%;
    height: 50px;
    background: #2A2F35;
    color: white;
    font-size: 25px;
    outline: none;
    border-style: none;
    font-family: "Poppins-Regular", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.cancelButton {
  @include loginBtn;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  &:hover {
    background: #1c2229;
  }
  &Verify {
    bottom: $activeMenuItem;
    background: $activeMenuItem;
    border-left: 0.5px white solid;
    &:hover {
      background: #d93858;
    }
  }
}

