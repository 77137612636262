@import '../../../mixins.scss';
@import '../../../variables.module.scss';

.wrapper {
  @include tableWrapper;
  position: relative;
  margin-top: 10px;
  min-height: 430px;
  max-height: 430px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #2A2F35;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2A2F35;
  }
}

.scheduleMenu {
  position: absolute;
}

.auditTable {
  @include table;
  position: relative;
}

.tableHead {
  @include tableHeadWrapper;
  border: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
}

.tableBody {
  @include tableBodyWrapper;
}

.tableDateItemsRow {
  display: block;
  position: relative;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: 14px;
  padding-bottom: 8px;
  margin-top: 20px;
  color: #444444;
  white-space: nowrap;
  padding-left: 15px;
}

.tableDate {
  cursor: pointer;
  margin-right: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -10px;
    top: 5px;
    transform: rotate(125deg);
  }

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -14px;
    top: 5px;
    transform: rotate(-310deg);
  }
}

.tableDateActive {
  cursor: pointer;
  margin-right: 40px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -10px;
    top: 5px;
    transform: rotate(45deg);
  }

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 1px;
    background-color: rgba(73, 84, 100, 0.9);
    position: absolute;
    left: -14px;
    top: 5px;
    transform: rotate(-45deg);
  }
}

.tableItems {
  position: absolute;
  left: 160px;
  top: -1px;
}


.tableLock {
  position: absolute;
  left: 8px;
  top: 10px;
  cursor: pointer;
}

.tableHeader {
  position: relative;
}

.tableHeader, .tableDescription {
  @include tableHeaderAndDescription;
}

.descriptionLeftPadding {
  padding-left: 30px !important;
}

.tableAccordion {
  display: block;
  cursor: pointer !important;
  height: 15px;
}

.tableSortingHeader {
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    color: $activeMenuItem;

    path {
      fill: $activeMenuItem;
    }
  }
}

.colHeader {
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}

.filterSvg {
  width: 13px;
  height: 13px;
  cursor: pointer;
  vertical-align: middle;
}

.endTimeCell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.timeBtn {
  background: transparent;
  outline: none;
  border-style: none;
  font-size: 20px;
  font-family: "Poppins-Regular", sans-serif;
  cursor: pointer;
  color: inherit;
}

.tableRow {
  height: 40px;
  position: relative;
}

.inputWrapper {
  width: 160px;
}

.applyBtn {
  height: 33px;
  background: #2A2F35;
  outline: none;
  color: white;
  cursor: pointer;
  border: none;
  transition: .3s;
  z-index: 100;

  &:hover {
    background: #EA4969;
    color: white;
    transition: .3s;
  }
}

.cancelBtn {
  margin-right: 20px;
}

.editableCell {
  cursor: pointer;
  transition: .3s;

  &:hover {
    text-decoration: underline #1c2229;
  }
}

.green {
  background-color: $greenTable;
}

.yellow {
  background-color: $yellowTable;
}

.red {
  background-color: $redTable;
}

.blue {
  background-color: #495464;
  color: white;
}

.lightBlue {
  background-color: #d5e4f3;
}
