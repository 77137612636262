.btn {
  display: block;
  width: 100%;
  height: 38px;
  font-family: "Poppins-Regular", sans-serif;
  background-color: #2A2F35;
  color: white;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
  text-align: center;

  &:hover,
  &:disabled {
    background-color: #dcdee1;
    color: #1c2229;
  }

  &:active {
    background-color: #bec3c9;
    color: #1c2229;
  }
}
