
.competitions {
    padding-top: 32px;
    padding-bottom: 32px;
    .filter {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        .filterLeftSide {
            display: flex;
            width: 85%;
            .clearFilterBtn {
                width: 100px;
                margin-top: 22px;
            }
        }
        .filterRightSide {
            display: flex;
            align-items: flex-end;
            .switch {
                align-self: flex-start;
                margin-top: 20px;
            }
        }
    }
    .competitionTableInner {
        padding-top: 30px;
    }
    .searchInput {
        margin-top: 2px;
    }
}
