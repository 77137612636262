@import '../../../mixins';

.decoder {
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  .inputs {
    display: flex;
    flex-direction: column;
    .addDecoderBtn {
      width: 140px;
      align-self: flex-end;
      margin-right: 20px;
      margin-top: 30px;
    }
  }
  .decodersTable {
    @include table;
    min-width: 400px;
  }
}
